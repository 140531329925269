import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = () => {
  const { strapiIndex } = useStaticQuery(graphql`
    query {
      strapiIndex {
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            id
            url
            localFile {
              url
            }
          }
        }
        blocks {
          __typename
          ...BlockHeroSection
          ...BlockResponsiveMedia
          ...MainSlider
          ...BaseCarSwapper
          ...BaseSectionTitle
          ...BaseMapBanner
          ...BlockPriceGrid
          ...BaseBlogSlider
          ...BaseGreenSection
          ...BasePromoBanner
          ...BlockVideoEmbed
          ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
            richTextBody: body {
              data {
                id
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          ...BlockImageSupport
          ...BaseNestedIconCard
        }
      }
    }
  `)
  const { defaultSeo, blocks } = strapiIndex

  return (
    <Layout>
      <Seo seo={defaultSeo} />
      <BaseBlocksRenderer blocks={blocks} />
    </Layout>
  )
}

export default IndexPage
